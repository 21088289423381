'use strict';

import $ from 'jquery';

import { v4 as uuidv4 } from 'uuid';
import Translate from './components/translator';
import { format, formatDistanceToNow, isMatch, parse, subDays, subWeeks, subMonths, subYears } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
const RegexParser = require('regex-parser');

const DATE_STRING_FORMAT = 'yyyy-MM-dd';
const DATETIME_STRING_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const Util = {
    regex: {
        uuidV4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        validUrl: /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
        validEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    sanitizer: {
        diacriticsMap: {},
        defaultDiacriticsRemovalMap: [
            {
                'base': 'a',
                'letters': '\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250'
            },
            {'base': 'aa', 'letters': '\uA733'},
            {'base': 'ae', 'letters': '\u00E6\u01FD\u01E3'},
            {'base': 'ao', 'letters': '\uA735'},
            {'base': 'au', 'letters': '\uA737'},
            {'base': 'av', 'letters': '\uA739\uA73B'},
            {'base': 'ay', 'letters': '\uA73D'},
            {'base': 'b', 'letters': '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253'},
            {'base': 'c', 'letters': '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184'},
            {
                'base': 'd',
                'letters': '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A'
            },
            {'base': 'dz', 'letters': '\u01F3\u01C6'},
            {
                'base': 'e',
                'letters': '\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD'
            },
            {'base': 'f', 'letters': '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C'},
            {
                'base': 'g',
                'letters': '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F'
            },
            {
                'base': 'h',
                'letters': '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265'
            },
            {'base': 'hv', 'letters': '\u0195'},
            {
                'base': 'i',
                'letters': '\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131'
            },
            {'base': 'j', 'letters': '\u006A\u24D9\uFF4A\u0135\u01F0\u0249'},
            {
                'base': 'k',
                'letters': '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3'
            },
            {
                'base': 'l',
                'letters': '\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747'
            },
            {'base': 'lj', 'letters': '\u01C9'},
            {'base': 'm', 'letters': '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F'},
            {
                'base': 'n',
                'letters': '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5'
            },
            {'base': 'nj', 'letters': '\u01CC'},
            {
                'base': 'o',
                'letters': '\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275'
            },
            {'base': 'oi', 'letters': '\u01A3'},
            {'base': 'ou', 'letters': '\u0223'},
            {'base': 'oo', 'letters': '\uA74F'},
            {'base': 'p', 'letters': '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755'},
            {'base': 'q', 'letters': '\u0071\u24E0\uFF51\u024B\uA757\uA759'},
            {
                'base': 'r',
                'letters': '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783'
            },
            {
                'base': 's',
                'letters': '\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B'
            },
            {
                'base': 't',
                'letters': '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787'
            },
            {'base': 'tz', 'letters': '\uA729'},
            {
                'base': 'u',
                'letters': '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289'
            },
            {'base': 'v', 'letters': '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C'},
            {'base': 'vy', 'letters': '\uA761'},
            {'base': 'w', 'letters': '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73'},
            {'base': 'x', 'letters': '\u0078\u24E7\uFF58\u1E8B\u1E8D'},
            {
                'base': 'y',
                'letters': '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF'
            },
            {
                'base': 'z',
                'letters': '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763'
            }
        ]
    },
    units: [
        ['m', 'km', 'dm', 'cm', 'mm', 'µm', 'nm', 'pm', 'in', 'ft', 'yd', 'mi'],
        ['g', 'kg', 'mg', 'lb', 'oz', 't'],
        ['l', 'kl', 'hl', 'ml', 'mm3', 'm3', 'dm3', 'cm3', 'gal', 'ft3', 'in3'],
        ['m2', 'km2', 'cm2', 'mm2', 'ft2', 'mi2'],
        ['w', 'kw', 'mw', 'gw'],
    ]
};

Util.initSanitizer = function() {
    for (let i = 0; i < Util.sanitizer.defaultDiacriticsRemovalMap.length; i++) {
        const letters = Util.sanitizer.defaultDiacriticsRemovalMap [i].letters;
        for (let j = 0; j < letters.length; j++) {
            Util.sanitizer.diacriticsMap[letters[j]] = Util.sanitizer.defaultDiacriticsRemovalMap [i].base;
        }
    }
};
Util.initSanitizer();

/**
 * Delete duplicate entrie in an array
 * Usefull combined with Array.concat() to make lists of products
 *
 * @param arr
 * @returns {any[]}
 */
Util.arrayUnique = function(arr) {
    return [...new Set(arr)];
};

/**
 *
 * @param plainTriggerStr
 * @returns {{namespace: (*|string), action: (*|string)}}
 */
Util.resolveAction = function(plainTriggerStr) {
    let _action = plainTriggerStr.split('.');
    return {
        namespace: _action[0],
        action: _action[1],
    };
};

/**
 *
 * @param val
 * @returns {string}
 */
Util.capitalize = function(val) {
    return val.charAt(0).toUpperCase() + val.slice(1);
};

/**
 *
 * @returns {string}
 */
Util.getLocale = function() {
    return ('undefined' !== typeof AppConfig && 'undefined' !== typeof AppConfig.locale)
        ? AppConfig.locale
        : 'fr';
};

Util.getFullLocale = function() {
    switch (this.getLocale()) {
        case 'fr':
        default:
            return 'fr-FR';
        case 'en':
            return 'en-US';
    }
};

/**
 *
 * @param {string} date
 * @returns {Date}
 */
Util.convertToDate = function(date) {
    return new Date(date);
};

/**
 *
 * @param {Date} date
 * @returns {string}
 */
Util.formatDatetime = function(date) {
    return date.toLocaleString(this.getFullLocale());
};

/**
 *
 * @param timestamp
 * @returns {string}
 */
Util.formatTimestamp = function(timestamp) {
    const _date = new Date(this.convertTimestampForJs(timestamp));
    return this.formatDatetime(_date);
};

/**
 *
 * @param timestamp
 * @returns {number}
 */
Util.convertTimestampForJs = function(timestamp) {
    return timestamp * 1000;
};

/**
 * @param {string} stringDate
 * @returns {boolean}
 */
Util.isValidDateString = function(stringDate) {
    return isMatch(stringDate, DATE_STRING_FORMAT);
};

/**
 * @param {string} stringDateTime
 * @returns {boolean}
 */
Util.isValidDateTimeString = function(stringDateTime) {
    return isMatch(stringDateTime, DATETIME_STRING_FORMAT);
};

/**
 * @param {string} stringDate
 * @param {boolean} getTime
 * @param {boolean} endOfDay
 * @returns {string}
 */
Util.convertStringDateToStringDateTime = function(stringDate, getTime = false, endOfDay = false) {
    if ('string' !== typeof stringDate || '' === stringDate) {
        return '';
    }

    const localeDateFormat = this.getLocaleDateFormat();

    const date = (stringDate.includes('/'))
        ? parse(stringDate, localeDateFormat, new Date())
        : new Date(stringDate);

    Util.setFullYearOnDate(stringDate, date);

    if (endOfDay) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
    }

    return format(
        date,
        (getTime)
            ? DATETIME_STRING_FORMAT
            : DATE_STRING_FORMAT
    );
};

/**
 * @param {string} isoDateString 
 * @returns {string}
 */
Util.convertIsoDateFormatToLocaleStringDate = function(isoDateString) {
    if (!this.isValidDateString(isoDateString) && !this.isValidDateTimeString(isoDateString)) {
        return '';
    }

    return format(new Date(isoDateString), this.getLocaleDateFormat());
};

/**
 * @param {string} stringDateTime
 * @param {boolean} getTime
 * @param {boolean} ignoreValidation
 * @returns {string}
 */
Util.convertStringDateTimeToLocaleStringDate = function(stringDateTime, getTime = false, ignoreValidation = false) {
    if (null === stringDateTime || (!ignoreValidation && !Util.isValidDateTimeString(stringDateTime))) {
        return '';
    }

    const date = new Date(stringDateTime);
    Util.setFullYearOnDate(stringDateTime, date);

    return format(date, this.getLocaleDateFormat(getTime));
};

/**
 * @param {string} stringDate
 * @param {Date} date
 */
Util.setFullYearOnDate = function(stringDate, date) {
    //new Date() in Google Chrome accepts first substring to be a month or a year only if it's not a year between 12 and 32
    //in our case, it's only a year format because of DateTime format, so we force it to be e.g 0099 to avoid "Invalid Date"
    if (stringDate.includes('-')) {
        const dateParts = stringDate.split('-');
        const year = dateParts[0];
        if (year < 100) {
            date.setFullYear(year, dateParts[1] - 1, dateParts[2].substring(0, 2));
        }
    }
};

/**
 * @param {boolean} getTime
 * @returns {string}
 */
Util.getLocaleDateFormat = function(getTime = false) {
    switch (this.getLocale()) {
        case 'fr':
        default:
            return 'dd/MM/yyyy' + (getTime
                ? ', HH:mm'
                : '');
        case 'en':
            return 'MM/dd/yyyy' + (getTime
                ? ', HH:mm a'
                : '');
    }
};

Util.convertDateTimeForHtmlInput = function(date) {
    return format(new Date(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

/**
 * @param {string} date in DateTime string format 'YYYY-MM-DD HH:mm:ss'
 * @returns {string}
 */
Util.getRelativeDate = function(date) {
    const dateTime = new Date(date);
    //Trick to avoid "in a few seconds" when the date is now
    dateTime.setSeconds(dateTime.getSeconds() - 20);

    return formatDistanceToNow(dateTime, {
        addSuffix: true,
        locale: this.getDateFnsLocale()
    });
};

Util.getDateFnsLocale = function() {
    switch (this.getLocale()) {
        case 'fr':
        default:
            return fr;
        case 'en':
            return enUS;
    }
};

/**
 *
 * @returns {boolean}
 * @param _number
 */
Util.isEven = function(_number) {
    return _number % 2 === 0;
};

/**
 *
 * @param paramName
 * @returns {*}
 */
Util.getParamCssEquivalent = function(paramName) {
    return this.strToKebabCase(paramName);
};

/**
 *
 * @param str
 * @returns {*}
 */
Util.strToKebabCase = function(str) {
    return str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-');
};

/**
 * Translate Variable using the Config.locale value
 *
 * @param _key
 * @param _parameters
 * @returns {string}
 */
Util.translate = function(_key, _parameters) {
    let string = Translate.translator.trans(_key, _parameters);
    const parametersRegex = new RegExp('\{(.*?)\}', 'gm');
    const listParameters = {};
    const listRules = {
        Plural: 'plural',
        Singular: 'singular'
    };

    let find;
    while (null !== (find = parametersRegex.exec(string))) {
        for (const [rule, ruleLower] of Object.entries(listRules)) {
            if (find[1].includes(rule)) {
                const terminologyPrefixeKey = find[1].split(rule)[0];
                listParameters[find[1]] = Translate.translator.trans(`${terminologyPrefixeKey}.${ruleLower}`);

                if (0 !== string.indexOf(find[0])) {
                    listParameters[find[1]] = listParameters[find[1]].toLowerCase();
                }
            }
        }
    }

    return Translate.translator.trans(string, listParameters);
};

/**
 * Check Translate.loaded
 * @returns {boolean}
 */
Util.translatorIsLoaded = function() {
    return Translate.loaded;
};

/**
 * Generate an uniqueId
 * /!\ It's incremental based, this shouldn't be used for any security behavior
 * @param prefix
 * @param suffix
 * @returns {*}
 */
Util.generateUniqueId = function(prefix, suffix) {
    return [
        'undefined' !== typeof prefix ? prefix : '',
        uuidv4(),
        'undefined' !== typeof suffix ? suffix : '',
    ].join('');
};

/**
 * Ajax Call with promises
 * @param params
 * @param callbacks
 * @returns {*}
 */
Util.ajax = function(params, callbacks) {
    if (undefined === params) {
        return;
    }

    ['success', 'error', 'complete'].forEach(_callback => {
        if (typeof callbacks[_callback] !== 'undefined') {
            params[_callback] = callbacks[_callback];
        }
    });

    return $.ajax(params);
};

/**
 * Loads a HTML template from a script[data-template] tag from DOM
 * & return it as a jQuery object to be manipulated
 *
 * @param templateName
 * @returns {jQuery|HTMLElement}
 */
Util.loadTemplate = function(templateName) {
    return $($('[data-template="' + templateName + '"]').html());
};

Util.populateTemplateVars = function($tpl, listVars) {
    for (let _varKey in listVars) {
        if (!listVars.hasOwnProperty(_varKey)) {
            continue;
        }

        let _varValue = listVars[_varKey];
        $($tpl).find('[data-placeholder="' + _varKey + '"]').html(_varValue);
    }

    return $tpl;
};

Util.getEmptyPromise = function() {
    return new Promise(resolve => { resolve(); });
};

/**
 * @param {string} email
 * @returns {boolean}
 */
Util.validateEmail = function (email) {
    //Validate if its an email format
    let regex = this.regex.validEmail;
    return regex.test(email);
};

/**
 * @param {string} url
 * @returns {boolean}
 */
Util.validateUrl = function(url) {
    //Validate if its an url format
    let regex = this.regex.validUrl;
    return regex.test(url);
};

/**
 * @param {string} pattern
 * @param {string} string
 * @returns {boolean}
 */
Util.testRegex = function(pattern, string) {
    if ('' === pattern) {
        return true;
    }
    return RegexParser(pattern).test(string);
};

/**
 * Truncate too long string
 *
 * @param {string} str
 * @param {string} length
 * @param {bool} useWordBoundary
 */
Util.truncateString = function(str, length, useWordBoundary = false) {
    if (str.length <= length) {
        return str;
    }
    const subString = str.substr(0, length - 1); // the original check
    return (useWordBoundary)
        ? subString.substr(0, subString.lastIndexOf(' '))
        : subString + '&hellip;';
};

Util.toggleInputError = function(id, hasError) {
    this.toggleClassError($(id), hasError);
    $('label[for="' + id.substr(1) + '"]').toggleClass('text-danger', hasError);
};

Util.toggleClassError = function($input, hasError) {
    $input.toggleClass('is-invalid', hasError);
};

/**
 *
 * @param {jQuery|HTMLElement} $element
 * @param {{}} options
 */
Util.initTooltip = function($element, options = {}) {
    $element.tooltip(options);
    $element.on('click', async function() {
        $(this).tooltip('hide');

        if ($(this).data('copy')) {
            $(this).attr('data-original-title', Util.translate('global.copied')).tooltip('show');
            await Util.sleep(1500);
            $(this).attr('data-original-title', Util.translate('global.actionsType.copy'));
        }
    });
};

/**
 *
 * @param {jQuery|HTMLElement} $element
 * @param {string} text
 */
Util.toggleTextTooltip = function($element, text = '') {
    if (text.length) {
        $element.attr('data-original-title', text);
        $element.tooltip('enable');
    } else {
        $element.tooltip('disable');
    }
};

Util.sanitizeString = function(string) {
    string = string.toLowerCase();
    string = string.replace(/ /g, '_');
    string = string.replace(/'/g, '');
    string = string.replace(/’/g, '');
    string = string.replace(/´/g, '');
    string = string.replace(/`/g, '');
    string = string.replace(/"/g, '');
    string = string.replace(/-/g, '_');
    string = string.replace(/[^\u0000-\u007E]/g, function(a) {
        return Util.sanitizer.diacriticsMap[a] || a;
    });
    string = string.replace(/[^A-Za-z0-9|\-]/g, '_');
    return string;
};

/**
 * Convert string into number and set it to 1 if less than 1
 * @param string
 * @returns {number}
 */
Util.convertStringToPositiveNumber = function(string) {
    let number = ('' === string)
        ? 1
        : parseInt(string, 10);

    return (number < 1)
        ? 1
        : number;
};

Util.sortableTableInput = function($table) {
    $table.find('thead th[data-sort="disabled"]').removeAttr('role').find('.fa-sort').addClass('d-none');
    $table.find('thead th:not([data-sort="disabled"])').on('click', function() {
        const columnIndex = $(this).index() - 1;

        const $tbody = $table.find('tbody');
        let $rows = $tbody.find('tr').toArray().sort(function(rowA, rowB) {
            const a = $(rowA).find('input').eq(columnIndex).val();
            const b = $(rowB).find('input').eq(columnIndex).val();
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            }
            return 0; // a = b
        });

        $(this).data('asc', !$(this).data('asc'));
        const ascending = $(this).data('asc');
        if (!ascending) {
            $rows = $rows.reverse();
        }

        $table.find('thead th:not([data-sort="disabled"]) .fa[data-sort!="disabled"]').addClass('d-none');
        $table.find('thead th:not([data-sort="disabled"]) .fa[data-sort="disabled"]').removeClass('d-none');
        $(this).find('.fa[data-sort="up"]').toggleClass('d-none', ascending);
        $(this).find('.fa[data-sort="down"]').toggleClass('d-none', !ascending);
        $(this).find('.fa[data-sort="disabled"]').addClass('d-none');

        $.each($rows, function() {
            $tbody.append(this);
        });

        $table.trigger($.Event('table.sorted'));
    });
};

/**
 * @param {string} url
 */
Util.stringIsUrlFile = function(url) {
    return (('undefined' !== typeof url) && isNaN(url) && (null !== url) && ('' !== url) && url.includes('/') && this.validateUrl(url));
};

/**
 * @param {string} fileName 
 * @returns {boolean}
 */
Util.fileNameIsImage = function(fileName) {
    return (null !== fileName.match(/\.(jpg|jpeg|png|gif|tif|tiff|psd|eps|ept|webp)(?![\w.\-_])/i)); //TODO: ITDB-79 Enum from App\Enum\FileExtensionEnum
};

/**
 * @param {string} fileName
 * @returns {boolean}
 */
Util.fileNameIsFlatImage = function(fileName) {
    return (null !== fileName.match(/.(jpg|jpeg|png|gif)(?![\w.\-_])/i)); //TODO: ITDB-79 Enum from App\Enum\FileExtensionEnum
};

/**
 * @param {string} fileName 
 * @returns {boolean}
 */
Util.fileNameIsPdf = function(fileName) {
    return (null !== fileName.match(/.(pdf)/i)); //TODO: ITDB-79 Enum from App\Enum\FileExtensionEnum
};

/**
 * @param {string} url
 * @param {string|null} emptyBehavior
 * @returns {string}
 */
Util.convertUrlToMedia = function(url, emptyBehavior = 'picto') {
    if (this.stringIsUrlFile(url)) {
        //TODO: check an arg boolean to display medias like video/audio/...
        if (this.fileNameIsImage(url)) {
            return `<img src="${url}" alt="" />`;
        } else if (this.fileNameIsPdf(url)) {
            return `<embed src="${url}" width="100%" height="100%" type="application/pdf">`;
        } else {
            return `<i class="fas fa-2x fa-${this.getPictoNameByFileUrl(url)}"></i>`;
        }
    } else {
        switch (emptyBehavior) {
            case 'text':
                return this.translate('global.noFile');
            case 'picto':
                return `<i class="fas fa-2x fa-${this.getPictoNameByFileUrl(url)}"></i>`;
            case 'empty':
                return '';
        }
    }
};

/**
 * @param {string} url
 * @returns {string}
 */
Util.getPictoNameByFileUrl = function(url) {
    //TODO: ITDB-79 Enum from App\Enum\FontAwesomeFileIconsEnum
    const extension = this.getFileNameExtension(url);
    switch (extension) { //TODO: ITDB-79 Enum from App\Enum\FileExtensionEnum
        case 'doc':
        case 'docx':
            return 'file-word';
        case 'xls':
        case 'xlsx':
            return 'file-excel';
        case 'ppt':
        case 'pptx':
            return 'file-powerpoint';
        case 'pdf':
            return 'file-pdf';
        case 'csv':
            return 'file-csv';
        case 'htm':
        case 'html':
            return 'file-code';
        case 'txt':
            return 'file-alt';
        case 'avi':
        case 'mp4':
        case 'mov':
            return 'file-video';
        case 'mp3':
            return 'file-audio';
        case 'zip':
        case 'rar':
        case '7z' :
            return 'file-archive';
        case '':
            return 'ban';
        case '_IS_MULTIPLE_VALUE_': //TODO: ITDB-79 Const from ProductService
            return 'photo-video';
        default:
            return 'file';
    }
};

Util.getFileNameExtension = function(fileName) {
    if (null === fileName || !fileName.includes('.') || fileName.endsWith('.')) {
        return '';
    }

    return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
};

/**
 * @param {{}} object
 * @param {[]} listExcludedProperties
 * @returns {number} nbProperties
 */
Util.countFilledObjectProperties = function(object, listExcludedProperties = []) {
    let nbProperties = 0;

    for (const property in object) {
        const isExcluded = listExcludedProperties.length > 0
            && -1 !== listExcludedProperties.indexOf(property)
        ;
        if ((listExcludedProperties.length === 0 || !isExcluded)
            && Util.isFilledObjectProperties(object, property)
        ) {
            nbProperties++;
        }
    }

    return nbProperties;
};

/**
 * Check if {object}.{property} exist and has a value
 * @param {{}} object
 * @param {string} property
 * @returns {boolean}
 */
Util.isFilledObjectProperties = function(object, property) {
    return object.hasOwnProperty(property)
        && ('undefined' !== typeof object[property])
        && (null !== object[property])
        && ('' !== object[property])
        && (!Array.isArray(object[property]) || Array.isArray(object[property]) && object[property].length)
    ;
};

/**
 * Check if given {objects} have the same keys
 * @param {objects}
 * @returns {boolean}
 */
Util.objectsHaveSameKeys = function (...objects) {
    const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    const union = new Set(allKeys);

    return objects.every(object => union.size === Object.keys(object).length);
};

/**
 * Conver centimeters to pixel with default resolution of 72DPI
 * @param {float} cm
 * @param {int} resolution
 * @returns {float}
 */
Util.convertCmToPx = function(cm, resolution = 72) {
    //1cm = (resolution / 2.54)px
    return Math.round((resolution / 2.54) * cm);
};

/**
 * Update {$headCheckbox} checked and indeterminate states regarding {$listCheckboxes}
 * @param {jQuery|HTMLElement} $headCheckbox
 * @param {jQuery|HTMLElement} $listCheckboxes
 */
Util.updateHeadCheckboxState = function($headCheckbox, $listCheckboxes) {
    const nbChannels = $listCheckboxes.length;
    const nbChecked = $listCheckboxes.filter(':checked').length;
    const hasChecked = (nbChecked > 0);
    $headCheckbox.prop({
        indeterminate: (nbChecked < nbChannels && hasChecked),
        checked: (nbChecked === nbChannels && hasChecked)
    });
};

/**
 * @param {{}} user
 * @param {bool} showName
 * @returns {*}
 */
Util.getHtmlUserAvatar = function(user = null, showName = true) {
    const $userAvatar = this.loadTemplate('user.avatar');
    const username = this.getUsername(user);

    if (null === user || null === user.id) {
        $userAvatar.find('img').remove();
        $userAvatar.find('.user-initials').text('?');
        if (showName) {
            $userAvatar.find('.panel__nav__label').html(username);
        } else {
            $userAvatar.find('.panel__nav__label').remove();
        }

        return $userAvatar.prop('outerHTML');
    }

    const avatarUrl = (null !== user.customizations && Object.keys(user.customizations).length > 0)
        ? user.customizations.avatar
        : '';

    if ('undefined' !== typeof avatarUrl && null !== avatarUrl && '' !== avatarUrl) {
        if (this.stringIsUrlFile(avatarUrl)) {
            $userAvatar.find('img').attr('src', avatarUrl);
            $userAvatar.find('.user-initials').remove();
        } else {
            $userAvatar.find('.user-initials, img').remove();
            $userAvatar.prepend(`<i class="fas fa-3x fa-${avatarUrl} align-middle"></i>`);
        }
    } else {
        $userAvatar.find('img').remove();
        $userAvatar.find('.user-initials').text(user.firstName.charAt(0) + user.lastName.charAt(0));
    }
    if (showName) {
        $userAvatar.find('.panel__nav__label').html(username);
    } else {
        $userAvatar.find('.panel__nav__label').remove();
    }


    return $userAvatar.prop('outerHTML');
};

/**
 * @param {string} userId
 * @param {[]} listGlobalUsers
 * @param {[]} listAllowedUsers
 * @param {[]} listDeletedUsers
 * @returns {{firstName, lastName: string, id}|{id: null}|null}
 */
Util.formatUserByContext = function(userId, listGlobalUsers, listAllowedUsers, listDeletedUsers) {
    let user = listGlobalUsers?.[userId] ?? null;
    let allowedUser = listAllowedUsers?.[userId] ?? null;
    let deletedUser = listDeletedUsers?.[userId] ?? null;

    if ('notExist' === userId) {
        return null;
    }

    if (!Util.isValidUuid(userId)) {
        //"Interactiv Technologies" user
        user = {
            id: userId,
            firstName: userId,
            lastName: ''
        };

        return user;
    }

    if (!user.roles.includes('ROLE_GLOBAL')
        && null === allowedUser
        && (null === deletedUser || !Util.hasPrivilege('all_users'))
    ) {
       return {id: null};
    }

    user.firstName = user.first_name;
    user.lastName = user.last_name;
    user = Object.assign({}, user);
    delete user.first_name;
    delete user.last_name;

    return user;
};

/**
 * @param {null|{}} user
 * @returns {string}
 */
Util.getUsername = function(user) {
    if (!Util.isEmpty(user) && Util.isEmpty(user.id)) {
        return Util.translate('users.anonymous');
    }

    if (Util.isEmpty(user)) {
        return Util.translate('users.deleted');
    }

    return `${user.firstName} ${user.lastName}`;
};

/**
 * @param {float} percent
 * @returns {string}
 */
Util.getColorByPercent = function (percent) {
    if (percent < 50) {
        return 'danger';
    } else if (percent < 80) {
        return 'warning';
    }

    return 'success';
};

Util.getArrayColumn = function(array, columnName) {
    return array.map(function(element) {
        if (element.hasOwnProperty(columnName)) {
            return element[columnName];
        }
    }).filter(function(element) {
        return 'undefined' !== typeof element;
    });
};

/**
 * @param {[]} listArrays
 * @returns {[]}
 */
Util.generateArraysCombinations = function(listArrays) {
    const combinations = [];
    const nbArrays = listArrays.length;

    // To keep track of next element in each of the nbArrays arrays
    const indices = new Array(nbArrays);

    // Initialize with first element's index
    for (let i = 0; i < nbArrays; i++) {
        indices[i] = 0;
    }

    while (true) {
        // Get current combination
        const currentCombination = [];
        for (let i = 0; i < nbArrays; i++) {
            currentCombination.push(listArrays[i][indices[i]]);
        }
        combinations.push(currentCombination);

        // Find the rightmost array that has more elements left after the current element in that array
        let next = nbArrays - 1;
        while (next >= 0 && (indices[next] + 1 >= listArrays[next].length)) {
            next--;
        }

        // No such array is found so no more combinations left
        if (next < 0) {
            return combinations;
        }

        // If found, move to next element in that array
        indices[next]++;

        // For all arrays to the right of this array current index again points to first element
        for (let i = next + 1; i < nbArrays; i++) {
            indices[i] = 0;
        }
    }
};

Util.getObjectArrayValues = function(object) {
    const array = [];
    $.each(Object.entries(object), function() {
        if ('getColumn' !== this[0]) { //can be inherit from Array.prototype.getColumn
            const listEntries = $.extend([], this[1]);
            listEntries.originalIndex = this[0];
            array.push(listEntries);
        }
    });

    return array;
};

/**
 * @param {string|null} text
 * @return {string}
 */
Util.addTilde = function(text) {
    if (null === text) {
        return '';
    }

    text = text.replaceAll(/\u00A0|&nbsp;/g, '~');

    return text;
};

/**
 * @param {string|null} text
 * @return {string}
 */
Util.removeTilde = function(text) {
    if (null === text) {
        return '';
    }

    text = text.replaceAll(/\u00A0|~/g, '&nbsp;');
    text = text.replace('<br data-mce-bogus="1">', '');
    
    return text;
};

/**
 * Translate an object with the current locale or the specified one
 * If the translations does not exist it will return the code of the object
 * To call this function your object must have at least a key 'code'
 *
 * @param {object} entity
 * @param {string|null} languageCode
 * @returns {string}
 */
Util.translateEntity = function(entity, languageCode = null) {
    if (null === entity || 'undefined' === typeof entity) {
        return '';
    }

    if ('string' === typeof entity) {
        return entity;
    }

    if (null === languageCode) {
        languageCode = this.getLocale();
    }

    if ('undefined' !== typeof entity.translations?.[languageCode]) {
        return entity.translations[languageCode];
    }

    if ('undefined' !== typeof entity.translations && 'undefined' !== typeof Object.values(entity.translations)[0]) {
        return Object.values(entity.translations)[0];
    }

    if ('undefined' !== typeof entity.name) {
        return entity.name;
    }

    return entity.code ?? '';
};

/**
 * @param {string} entityName
 * @param {string} id
 * @returns {string}
 */
Util.getEntityEditUrl = function (entityName, id) {
    let module = '';
    let suffixe = '';

    switch (entityName) {
        case 'customer':
        case 'user':
        case 'role':
            module = 'admin';
            break;
        case 'attribute':
        case 'attributesGroup':
        case 'category':
        case 'channel':
        case 'product':
        case 'declination':
        case 'referential':
        case 'workflow':
            module = 'pim';
            if ('category' === entityName) {
                //there is no category edition, this is in the channel edition
                entityName = 'channel';
                suffixe = '#channel-categories-list';
            } else if ('declination' === entityName) {
                entityName = 'product';
            }
            break;
        case 'file':
            module = 'dam';
            break;
        case 'folder':
            //there is no folder edition page, this is in the folders list
            return AppConfig.listUrls.dam.folder + 'list';
        case 'family':
            //there is no family edition page, this is in the families list
            return AppConfig.listUrls.pim.family + 'list';
        default:
            return '';
    }

    return `${AppConfig.listUrls[module][entityName]}edit/${id}${suffixe}`;
};

/**
 * @param {string} string
 * @returns {string}
 */
Util.capitalize = function(string) {
    return string[0].toUpperCase() + string.slice(1);
};

/**
 * @param {int} ms
 * @returns {Promise<unknown>}
 */
Util.sleep = function(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
};

/**
 * @param {string} fileCode
 * @param {boolean} useCdn
 * @returns {string}
 */
Util.getDamUrl = function(fileCode, useCdn = true) {
    if (Util.stringIsUrlFile(fileCode)) {
        return fileCode;
    }

    const baseUrl = (useCdn)
         ? `${Util.getCdnUrl()}public/`
         : Util.getApiUrl();

    return `${baseUrl}${AppConfig.userId}/file/display/${fileCode}`;
};

/**
 * @param {null|string} url
 * @returns {null|string}
 */
Util.getFileCodeFromDamUrl = function(url) {
    if (null === url || !Util.isUrlFromDam(url)) {
        return null;
    }

    return url.split('/').pop();
};

/**
 * @param {string} url
 * @returns {boolean}
 */
Util.isUrlFromDam = function(url) {
    return url.includes(Util.getApiUrl()) || url.includes(Util.getCdnUrl());
};

/**
 * @param {string} fileCode 
 * @param {{}|null} format
 */
Util.openFileUrl = function(fileCode, format = null) {
    let url = fileCode;
    if (!Util.stringIsUrlFile(fileCode)) {
        let damUrl = Util.getDamUrl(fileCode);
        url = damUrl.replace('display', 'download');
        if (null !== format) {
            url += `?formatCode=${format.code}&noCache=${new Date().getTime()}`;
        } else {
            url += `?noCache=${new Date().getTime()}`;
        }
    }
    window.open(url, '_blank').focus();
};

/**
 * @param {string} privilegeName
 * @returns {boolean}
 */
Util.hasPrivilege = function(privilegeName) {
    if ('exports_edit' === privilegeName) {
        privilegeName = 'exports_list';
    }

    return -1 !== AppConfig.userPrivileges.indexOf(privilegeName);
};

/**
 * @param {string} featureName 
 * @returns {boolean}
 */
Util.hasFeature = function(featureName) {
    if ('fab_dis' === featureName) {
        const listFabDisVersion = ['fab_dis_23', 'fab_dis_30'];
        //TODO: ITDB-79 Enum from App\Enum\FeatureToggleEnum FAB_DIS_FEATURES
        for (let i = 0; i < listFabDisVersion.length; i++) {
            if (AppConfig.toggledFeatures.includes(listFabDisVersion[i])) {
                return true;
            }
        }

        return false;
    }

    return AppConfig.toggledFeatures.includes(featureName);
};

/**
 * @param {{}} object
 * @returns {{}}
 */
Util.sortObjectByKeys = function(object) {
  return Object.keys(object).sort().reduce(
      (obj, key) => {
          obj[key] = object[key];
          return obj;
      },
      {}
  );
};

/**
 * @returns {string}
 */
Util.getOpenedTab = function() {
    const currentUrl = window.location.href;
    return currentUrl.slice(currentUrl.lastIndexOf('#') + 1);
};

/**
 * @param string
 * @returns {boolean}
 */
Util.isValidUuid = function(string) {
  return this.regex.uuidV4.test(string);
};

/**
 * Transform initial array into object, for example to deal with JSON.stringify content
 * @param array
 * @returns {{}}
 */
Util.castArrayToObject = function(array) {
    return $.extend(
        {},
        array
    );
};

Util.getApiUrl = function() {
    return ('undefined' !== typeof AppConfig.customizations.domainName
            && null !== AppConfig.customizations.domainName
            && '' !== AppConfig.customizations.domainName)
                ? AppConfig.customizations.domainName + 'api/public/'
                : AppConfig.listUrls.api.public;
};

Util.getCdnUrl = function() {
    return AppConfig.cdnUrl;
};

/**
 * @param number
 * @returns {string}
 */
Util.convertFromScientificNotation = function(number) {
    if (Math.abs(number) < 1.0) {
        const e = parseInt(number.toString().split('e-')[1]);
        if (e) {
            number *= Math.pow(10,e - 1);
            number = '0.' + (new Array(e)).join('0') + number.toString().substring(2);
        }
    } else {
        let e = parseInt(number.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            number /= Math.pow(10,e);
            number += (new Array(e + 1)).join('0');
        }
    }

    return number;
};
    
/**
 * @param {string} name
 * @param {string} value
 * @param {string|null} days
 */
Util.createCookie = function(name, value, days = null) {
    let expires = '';
    if (null !== days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }

    document.cookie = `${name}=${value}${expires}; path=/`;
};

/**
 * @param {boolean} deepClear
 */
Util.clearPimContextLocalStorage = function(deepClear = false) {
    localStorage.removeItem('order');
    localStorage.removeItem('pageLength');
    localStorage.removeItem('categoryCode');
    localStorage.removeItem('categoryId');
    localStorage.removeItem('channelCode');
    localStorage.removeItem('languageCode');
    localStorage.removeItem('search');
    localStorage.removeItem('displayMode');
    localStorage.removeItem('includeChildrenCategories');
    localStorage.removeItem('filters');
    localStorage.removeItem('listAttributeFamilyGeneration');

    if (deepClear) {
        localStorage.removeItem('pimContext');
        localStorage.removeItem('displayStart');
    }
};

Util.clearDamContextLocalStorage = function() {
    localStorage.removeItem('folderId');
    localStorage.removeItem('folderCode');
    localStorage.removeItem('damPageLength');
    localStorage.removeItem('damOrder');
    localStorage.removeItem('damDisplayStart');
    localStorage.removeItem('fileFilters');
    localStorage.removeItem('hasMicrosoftAccessToken');
    localStorage.removeItem('microsoftAccessTokenExpirationDate');
};

Util.clearExportsImportsLocalStorage = function() {
    localStorage.removeItem('exports-sort');
    localStorage.removeItem('imports-sort');
};

Util.cleanLocalStorageForImpersonate = function() {
    localStorage.removeItem('listSearchAttributes');
    localStorage.removeItem('declinationColumnsOrder');
    this.clearPimContextLocalStorage(true);
    this.clearDamContextLocalStorage();
    this.clearExportsImportsLocalStorage();
};

/**
 * @param {{}} object 
 * @returns 
 */
Util.convertObjectToArray = function(object) {
    const array = [];
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            array.push(object[key]);
        }
    }

    return array;
};

/**
 * @param {string} newUrl
 */
Util.replaceCurrentUrl = function(newUrl) {
    window.history.pushState('object', document.title, newUrl);
};

/**
 * @param {string} url
 * @returns {string}
 */
Util.removeGetParametersFromUrl = function(url) {
    const splittedUrl = url.split('?');
    if ('undefined' === typeof splittedUrl[1]) {
        return url;
    }

    let newUrl = splittedUrl[0];
    const currentAnchor = url.split('#')[1] ?? '';
    if ('' !== currentAnchor) {
        newUrl += '#' + currentAnchor;
    }

    return newUrl;
};

Util.getListProductTemplates = function() {
    return new Promise((resolve, reject) => {
        Util.ajax({
            method: 'GET',
            url: AppConfig.listUrls.w2p.productTemplate + 'get-list', /*TODO: FOSJsRoutingBundle*/
            dataType: 'json',
        }, {
            success: function (response) {
                if (!response.error) {
                    return resolve(response);
                }
                reject();
            },
            error: function() {
                reject();
            }
        });
    });
};

Util.getListPageTemplates = function() {
    return new Promise((resolve, reject) => {
        Util.ajax({
            method: 'GET',
            url: AppConfig.listUrls.w2p.pageTemplate + 'get-list', /*TODO: FOSJsRoutingBundle*/
            dataType: 'json',
        }, {
            success: function (response) {
                if (!response.error) {
                    return resolve(response);
                }
                reject();
            },
            error: function() {
                reject();
            }
        });
    });
};

/**
 * @param {string} unit
 * @returns {[]}
 */
Util.getRelatedUnits = function(unit) {
    if ('string' !== typeof unit) {
        return [];
    }

    for (let i = 0; Util.units.length > i; i++) {
        if (Util.units[i].indexOf(unit) !== -1) {
            return Util.units[i];
        }
    }

    return [];
};

/**
 * Create an associative array of given length {width} {height}
 * @param width
 * @param height
 * @param value
 * @returns {[]}
 */
Util.createAssociativeArray = function (width, height, value = null) {
    return Array.apply(null, {length: height}).map(function () {
        return Array.apply(null, {length: width}).map(function () {
            return value;
        });
    });
};

/**
 * @param {string} color
 * @returns {string}
 */
Util.formatHexColor = function(color) {
    if ('string' !== typeof color || '' === color) {
        return '';
    }

    if (!color.includes('#')) {
        color = '#' + color;
    }

    if (/^#([0-9a-f]{6}){1,2}$/i.test(color)) { //valid hex string i.e #ffffff
        return color;
    }

    if (/^#([0-9a-f]{3}){1,2}$/i.test(color)) { //valid hex 3-digit i.e #fff
        return color;
    }

    return '';
};

/**
 * @param {int|float} number
 * @param {[]} range
 * @returns {boolean}
 */
Util.isNumberInRange = function(number, range) {
    return number >= range[0] && number <= range[1];
};

/**
 * Util.join(['toto', 'titi', 'lulu']) returns 'toto, titi or lulu'
 * Util.join(['toto', 'titi']) returns 'toto or titi'
 *
 * @param {[]} array
 * @param {string} delimiter1
 * @param {string} delimiter2
 * @returns {string}
 */
Util.join = function(array, delimiter1 = ', ', delimiter2 = ` ${Util.translate('global.or')} `) {
    return !Array.isArray(array) ? array
            : !array.length ? ''
            : 1 === array.length ? array[0]
                : 2 === array.length ? array[0] + delimiter2 + array[1]
                    : array.slice(0, -1).join(delimiter1) + delimiter2 + array[array.length - 1];
};

/**
 * @param {jQuery|HTMLElement} $element
 * @param {string} property
 * @param {number} ratio
 * @param {boolean} ignoreAlreadyResized
 */
Util.resizeCssProperty = function($element, property, ratio, ignoreAlreadyResized = false) {
    if (!ignoreAlreadyResized && $element.hasClass('resized')) {
        return;
    }

    const propertyValue = $element.css(property);

    if ('undefined' !== typeof $element.attr('style')
        && $element.attr('style').includes(property)
        && '' !== propertyValue
    ) {
        let value = propertyValue.replace('px', '');
        value *= ratio;
        $element.css(property, `${value}px`).addClass('resized');
    }
};

/**
 * @param {string} url 
 * @returns {string}
 */
Util.removeTrailingSlash = function(url) {
    return url.replace(/\/$/, '');
};

/**
 * @param {string} entityType 
 * @param {string} entityId 
 * @param {null|string} anchor 
 * @returns 
 */
Util.getEditUrl = function(entityType, entityId, anchor = null) {
    let url = '/';
    switch (entityType) {
        case 'products':
            url = AppConfig.listUrls.pim.product + 'edit/' + entityId;

            break;
        case 'files':
            url = AppConfig.listUrls.dam.file + 'edit/' + entityId;

            break;
    }

    if (null !== anchor) {
        url += '#' + anchor;
    }

    return url;
};

/**
 * @param {string} channelId 
 * @param {{}} product 
 * @returns 
 */
Util.getProductDefaultBreadcrumb = function(channelId, product) {
    if (null === product.list_default_categories || 'undefined' === typeof product.list_default_categories[channelId]) {
        return product.list_breadcrumb[0] ?? [];
    }
    for (let i = 0; product.list_breadcrumb.length > i; i++) {
        const lastIndex = product.list_breadcrumb[i].length - 1;
        if (product.list_breadcrumb[i][lastIndex].id === product.list_default_categories[channelId]) {
            return product.list_breadcrumb[i];
        }
    }
    return product.list_breadcrumb[0] ?? [];
};

/**
 * @param {*} value
 * @returns {boolean}
 */
Util.isEmpty = function(value) {
    if  ('' === value
        || null === value
        || 'undefined' === typeof value
    ) {
        return true;
    }

    if (value instanceof Date) {
        return false;
    }

    if ('object' === typeof value) {
        return 0 === Object.keys(value).length;
    }

    return Array.isArray(value) && !value.length;
};

Util.getPathInfos = function(fileName) {
    const file = fileName.split('/').pop();

    return {fileName: file.substr(0, file.lastIndexOf('.')), extension: file.substr(file.lastIndexOf('.') + 1, file.length)};
};

Util.getNextMonthName = function (date, longLength = true, next = 1) {
    const nextMonth = new Date(date.setMonth(date.getMonth() + next));

    return nextMonth.toLocaleString(this.getFullLocale(), { month: longLength ? 'long' : 'short' }).toLowerCase();
};

Util.arraysEqual = function(a, b) {
    if (a === b) {
        return true;
    }

    if (null === a || null === b) {
        return false;
    }

    if (a.length !== b.length) {
        return false;
    }

    const sortedA = a.slice().sort();
    const sortedB = b.slice().sort();

    return sortedA.every((value, index) => {
        if (typeof value === 'object' && typeof sortedB[index] === 'object') {
            return Util.objectsEqual(value, sortedB[index]);
        } else {
            return value === sortedB[index];
        }
    });
};

Util.objectsEqual = function(a, b) {
    if (null === a || null === b) {
        return a === b;
    }
    if (Object.keys(a).length !== Object.keys(b).length) {
        return false;
    }

    for (let key in a) {
        if (a.hasOwnProperty(key)) {
            if (Array.isArray(a[key]) && Array.isArray(b[key])) {
                return Util.arraysEqual(a[key], b[key]);
            }

            if (a[key] !== b[key]) {
                return false;
            }
        }
    }

    return true;
};

/**
 * @param {string} mergedCell
 * @returns array
 */
Util.calculateCellSpan = function(mergedCell) {
    const specifiedCells = mergedCell.split(':');
    const firstRow = specifiedCells[0].replace(/\D/g,'');
    const lastRow = specifiedCells[1].replace(/\D/g,'');

    const rowSpan = lastRow - firstRow + 1;

    const firstColumn = specifiedCells[0].replace(/[0-9]/g, '');
    const lastColumn = specifiedCells[1].replace(/[0-9]/g, '');

    const firstColumnIndex = Util.excelLetterToNumericColumn(firstColumn);
    const colSpan = Util.excelLetterToNumericColumn(lastColumn) - Util.excelLetterToNumericColumn(firstColumn) + 1;

    return {rowSpan, colSpan, column: firstColumnIndex, row: firstRow - 1};
};

Util.excelLetterToNumericColumn = function(letters) {
    let column = 0;
    for (let i = 0; i < letters.length; i++) {
        column += (letters.charCodeAt(i) - 64) * Math.pow(26, letters.length - i - 1);
    }

    return column - 1;
};

/**
 * @param {int} col
 * @param {int} row
 * @returns string
 */
Util.numericToExcelPos = function(col, row) {
    return this.numericToExcelCol(col) + row;
};

/**
 * @param {int} row
 * @returns string
 */
Util.numericToExcelCol = function(col) {
    let stringCol = '';
    for (++col; col; col = Math.floor((col - 1) / 26)) {
        stringCol = String.fromCharCode(((col - 1) % 26) + 65) + stringCol;
    }

    return stringCol;
};

/**
* @param {string} calculationRule
* @returns {{}}
*/
Util.getCalculationRuleListAttributesCodes = function(calculationRule) {
    'use strict';
    const listAttributesCodes = {};
    const regex = /\[(.*?)\]/g;

    let attributeParameter;
    while ((attributeParameter = regex.exec(calculationRule))) {
        const attributeCode = attributeParameter[1];
        if (-1 === attributeCode.indexOf('product_cpt') && -1 === attributeCode.indexOf('declination_product_cpt')) {
            listAttributesCodes[attributeCode] = '';
        }
    }

    return listAttributesCodes;
};

/**
 * @param {string} name
 * @returns {{color: string, bgColor: string}}
 */
Util.getColorsByBadgeName = function (name) {
    switch (name) {
        case 'success':
            return {
                color: '#4c9c22',
                bgColor: '#cff0be'
            };
        case 'danger':
            return {
                color: '#dc3545',
                bgColor: '#f9d8d8'
            };
        case 'warning':
            return {
                color: '#ce9b00',
                bgColor: '#fbebd2'
            };
        case 'info':
            return {
                color: '#098194',
                bgColor: '#b9eaf3'
            };
        case 'primary':
            return {
                color: '#264fa7',
                bgColor: '#7cade8'
            };
        default:
        case 'secondary':
            return {
                color: '#5b5b5b',
                bgColor: '#dedede'
            };
        case 'light':
            return {
                color: '#adadad',
                bgColor: '#f5faff'
            };
        case 'dark':
            return {
                color: '#343a40',
                bgColor: '#b3b3b3'
            };
    }
};

/**
 * @param {string} dateString
 * @returns string
 */
Util.convertDateAgoToDateString = function(dateString) {
    const currentDate = new Date();

    if ('today' === dateString) {
        return format(currentDate, 'yyyy-MM-dd');
    }

    if ('-' !== dateString.charAt(0) || !['D', 'W', 'M', 'Y'].includes(dateString.charAt(dateString.length - 1))) {
        //Invalid dateString format. It must start with "-" and end with "D", "W", "M", or "Y".'
        return dateString;
    }

    const value = parseInt(dateString.slice(1, -1));
    const unit = dateString.charAt(dateString.length - 1).toUpperCase();

    let newDate;
    switch (unit) {
        case 'D':
            newDate = subDays(currentDate, value);
            break;
        case 'W':
            newDate = subWeeks(currentDate, value);
            break;
        case 'M':
            newDate = subMonths(currentDate, value);
            break;
        case 'Y':
            newDate = subYears(currentDate, value);
            break;
        default:
            return dateString;
    }

    return format(newDate, 'yyyy-MM-dd');
};

Util.getISO6392ByLanguageCode = function(languageCode) {
    //TODO: ITDB-79 Enum from App\Enum\LanguageEnum
    const iso639_1_to_2 = {
        'de': 'deu', // German
        'en': 'eng', // English
        'es': 'spa', // Spanish
        'fr': 'fra', // French
        'it': 'ita', // Italian
        'nl': 'nld', // Dutch
        'pl': 'pol', // Polish
        'ru': 'rus', // Russian
        'ar': 'ara', // Arabic
        'fi': 'fin', // Finnish
        'el': 'ell', // Greek
        'no': 'nor', // Norwegian
        'ro': 'ron', // Romanian
        'sv': 'swe', // Swedish
        'tr': 'tur', // Turkish
        'pt': 'por', // Portuguese
        'da': 'dan', // Danish
        'he': 'heb', // Hebrew
        'cs': 'ces', // Czech
    };

    return iso639_1_to_2[languageCode];
};

Util.copyObject = function(object) {
    return JSON.parse(
        JSON.stringify(object)
    );
};

Util.getCurrentDate = function() {
  return format(new Date(), 'yyyy-MM-dd');
};

Util.getCurrentDateTime = function() {
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
};

Util.encodeQueryParams = function(params) {
    return Object.keys(params).map(key => {
        if (Util.isEmpty(params[key])) {
            return null;
        }
        if (Array.isArray(params[key])) {
            return params[key].map(value =>
                `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`
            ).join('&');
        }
        if (typeof params[key] === 'object') {
            return Object.keys(params[key]).map(subKey =>
                `${encodeURIComponent(key)}[${encodeURIComponent(subKey)}]=${encodeURIComponent(params[key][subKey])}`
            ).join('&');
        } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        }
    })
    .filter(param => param !== null)
    .join('&');
};

Util.escapeRegExp = function (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

global.Util = Util;

export default Util;
