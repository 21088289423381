import Translator from 'bazinga-translator';
window.Translator = Translator;

Translator.placeHolderPrefix = '{';
Translator.placeHolderSuffix = '}';

let _translate = {
    loaded: false
};

_translate.translator = Translator;

/**
 *
 * @param id
 * @param parameters
 * @param domain
 * @param locale
 * @returns {String}
 */
_translate.trans = function(id, parameters, domain, locale) {
    return this.translator.trans(id, parameters, domain, locale);
};

_translate.init = function() {
    const cacheKey = 'translator' + AppConfig.version;
    if ('dev' !== AppConfig.env && null !== localStorage.getItem(cacheKey)) {
        const storedTranslator = JSON.parse(localStorage.getItem(cacheKey));
        if ('undefined' !== typeof storedTranslator.translations[_translate.translator.locale]
            && Object.keys(storedTranslator.translations[_translate.translator.locale]).length
        ) {
            _translate.translator.fromJSON(storedTranslator);
            this.triggerLoaded();
            
            return;
        }
    }

    this.clearOldStorage();
    $.getJSON(AppConfig.listUrls.translations, {}, (response) => {
        response = this.applyTerminologies(response);
        this.translator.fromJSON(response);
        localStorage.setItem(cacheKey, JSON.stringify(response));
        this.triggerLoaded();
    });
};

_translate.clearOldStorage = function() {
    for (const key in localStorage) {
        if (key.startsWith('translator')) {
            localStorage.removeItem(key);
        }
    }
};

/**
 * @param {{}} response 
 */
_translate.applyTerminologies = function(response) {
    if ('undefined' !== typeof AppConfig.customizations.terminologies) {
        for (const [entity, languagesValues] of Object.entries(AppConfig.customizations.terminologies)) {
            for (const [languageCode, listValues] of Object.entries(languagesValues)) {
                if ('undefined' === typeof response.translations[languageCode]) {
                    continue;
                }
                response = _translate.insertTerminology(response, languageCode, entity, listValues);
            }
        }
    }

    return response;
};

/**
 * 
 * @param {{}} response 
 * @param {string} languageCode 
 * @param {string} entity 
 * @param {{}} listValues 
 * @returns {{}} 
 */
_translate.insertTerminology = function(response, languageCode, entity, listValues) {
    entity = entity.replaceAll('_', '.', );
    entity = entity.replaceAll('-', '_');
    ['singular', 'plural'].forEach(function(translationMultiplicity) {
        const currentValues = listValues[translationMultiplicity];
        if ('undefined' === typeof currentValues || null === currentValues) {
            return;
        }
        for (const value of Object.values(currentValues)) {
            if (null !== value) {
                response.translations[languageCode]['messages+intl-icu'][`${entity}.${translationMultiplicity}`] = value;
                break;
            }
        }             
    });

    return response;
};

_translate.triggerLoaded = function() {
    this.loaded = true;
    $('body').trigger('translations.loaded');
};

export default _translate;